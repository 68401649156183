import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SurveyorService } from 'src/app/services/SurveyorService/surveyor.service';

@Component({
  selector: 'app-bad-feedback',
  animations: [
    trigger('borderChange', [
      state('off', style({
        border: '2px solid #00000000',
        background: 'white',
        color: 'black'
      })),
      state('on', style({
        border: '2px solid #6255ba',
        background: '#8777d9',
        color: 'white'
      })),
      state('onGood', style({
        border: '2px solid #4aaf85',
        background: '#57d9a3',
        color: 'white'
      })),
      transition('off => on', [
        animate('0.3s')
      ]),
      transition('on => off', [
        animate('0.3s')
      ]),
      transition('off => onGood', [
        animate('0.3s')
      ]),
      transition('onGood => off', [
        animate('0.3s')
      ]),
    ]),

  ],
  templateUrl: './bad-feedback.component.html',
  styleUrls: ['./bad-feedback.component.css']
})
export class BadFeedbackComponent implements OnInit {
  reasons = [
    'Uncooperative Behaviour',
    'Incomplete Information',
  ]
  selectionControl: FormControl = new FormControl([])
  commentControl: FormControl = new FormControl('')
  badFeedback: boolean = false;
  textDisplay = 'We are sorry to hear about your experience with us.';
  urlParams: any = {
    claimID: '',
    requestID: '',
    isGoodFeedback: 0,
    encClaimID: ''
  };
  feedbackData: any;
  feedbackText = 'Tell us more about what did you like the most?'
  templateData: any = '';
  showRight: boolean = false;
  constructor(private activatedRouter: ActivatedRoute, private surveyorService: SurveyorService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.urlParams.claimID = atob(this.activatedRouter.snapshot.paramMap.get('claimID'));
    this.urlParams.isFeedbackType = this.urlParams.claimID.split(':')[this.urlParams.claimID.split(':').length - 1];
    // this.urlParams.isFeedbackType = 3;
    this.badFeedback = this.urlParams.isFeedbackType == 1 ? false : true;
    this.urlParams.encClaimID = this.urlParams.claimID.split(':')[0];
    this.urlParams.requestID = this.activatedRouter.snapshot.paramMap.get('requestID') ? atob(this.activatedRouter.snapshot.paramMap.get('requestID')) : null;
    this.changeMessage();
    this.initFeedbackSubmit(true);
    // if(!this.badFeedback) {
    //   this.getCustomerFeedbackTemplate();
    // }
  }

  selectReason(event, reason, index) {
    let currentValues = this.selectionControl.value;
    if(event?.target?.checked) {
      currentValues.push(reason);
      this.selectionControl.patchValue(currentValues);
    }
    else {
      currentValues.splice(index, 1);
      this.selectionControl.patchValue(currentValues)
    }
    console.log(this.selectionControl.value);
  }

  changeMessage() {
    if(this.urlParams.isFeedbackType == 1) {
      this.textDisplay = 'Thank you! We look forward to serving you again.'
      this.feedbackText = 'Tell us more about what did you like the most?'
    }
    else {
      this.textDisplay = 'We are sorry to hear about your experience with us.'
      this.feedbackText = 'What went wrong in our assistance?'
    }
  }

  initFeedbackSubmit(init = false) {
    let payload = {
      "AgentRating": this.urlParams.isFeedbackType,  // 1 For Good and 3 For Bad
      "IsSubmit": true,
      "CreatedBy": 124,
      "EncClaimID": this.urlParams.encClaimID,
      "FeedbackRequestId": this.urlParams.requestID || null,
      "IsV2Version": this.urlParams.requestID ? true : false,
      "FeedbackQuesAns": [
        {
          "Question": "How was your experience?",
          "InputType": "Feedback",
          "Response": this.urlParams.isFeedbackType == 1 ? 'Good' : 'Bad'
        }
      ],
    }
    if (this.urlParams.isFeedbackType == 1 && !init) {
      let pushObj = {
        "Question": "Comments",
        "InputType": "Text",
        "Response": this.commentControl.value
      }
      payload.FeedbackQuesAns.push(pushObj);
    }
    else if (this.urlParams.isFeedbackType == 3 && !init) {
      let pushObjReasons = {
        "Question": "What went wrong in our assistance?",
        "InputType": "Selection",
        "Response": this.selectionControl.value.toString()
      }
      payload.FeedbackQuesAns.push(pushObjReasons);
      let pushObjComments = {
        "Question": "Comments",
        "InputType": "Text",
        "Response": this.commentControl.value
      }
      payload.FeedbackQuesAns.push(pushObjComments);
    }

    this.surveyorService.setCustomerFeedback(payload).subscribe((data: any) => {
      if (data.Data != null) {
        if ((data?.Data?.FeedbackType == 'ClaimsFeedbackPB' || data?.Data?.FeedbackType == 'HealthClaimsFeedback') && data?.Data?.ProductId != 114) {
          if (!this.badFeedback) {
            this.getCustomerFeedbackTemplate();
            this.showRight = true;
            document.getElementById('leftPanel').style.animationPlayState = 'running';
          }
        }

        if (init) {
          this.getCustomerFeedback();
        }

        if (!init) {
          this.feedbackData.FeedbackType = '';
        }
      }
    })
  }

  getCustomerFeedback() {
    this.surveyorService.getCustomerFeedback(btoa(this.urlParams.encClaimID)).subscribe((data: any) => {
      this.feedbackData = data.Data[0];
    })
  }

  getCustomerFeedbackTemplate() {
    this.surveyorService.getCustomerFeedbackTemplate(btoa(this.urlParams.encClaimID)).subscribe((data: any) => {
      if(data?.Data) {
        this.templateData = data.Data;
        this.commentControl.patchValue(this.templateData?.TemplateDescription || '')
      }
    })
  }

  sendPost(value) {
    let payload = {
      "enctxt": this.templateData?.TemplateName || '',
      "socialId": value,
      "Description": this.commentControl.value,
      "DeviceType": this.getDeviceType() || '',
      "FeedbackType": this.feedbackData?.FeedbackType || '',
    }

    this.surveyorService.sendAppreciationPost(payload).subscribe((data: any) => {
      if(data?.Data && data?.ErrorCode == 0) {
        window.open(data.Data, "_blank");
      }
      else {
        this.snackBar.open(data.Data || "Something went wrong. Please try again later.", null, {
          duration: 3000,
          panelClass: ['error-snack'],
        });
      }
    })
  }

  getDeviceType() {
    let deviceType;
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      deviceType = "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      deviceType = "iOS";
    } else {
      deviceType = "Desktop";
    }

    // window.alert(deviceType);
    
    return deviceType;
  }

  isMobile() {
    var check = false;
    (function (a, b) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent);
    return check;
  }
}
